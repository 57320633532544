<!--
* @FileDescription: 拉水-选择车辆
* @Date: 2020年11月12日10:59:06
* @Author: DY
-->
<template>
    <div class="selectVehicle">
        <div class="table-content table-bg">
            <div class="search-wrapper">
                <button class="select_btn" @click="selectWaitCarChange">
                    选择
                </button>
                <div class="search">
                    <el-input v-model="searchWaitCarValue" placeholder="请输入搜索车号...">
                        <template slot="append">
                            <button class="search-btn" @click="searchWaitCarChange()">
                                <i class="el-icon-search"></i>
                            </button>
                        </template>
                    </el-input>
                </div>
            </div>
            <el-table
                :data="showDialogWaitCarList"
                tooltip-effect="dark"
                style="width: 100%"
                highlight-current-row
                height="calc(100% - 0.5rem)"
                @row-click="rowClickWaitCarChange"
                :header-row-style="{background: '#edf0f5 !important',color: '#022782'}"
            >
                <el-table-column
                    width="55"
                    fixed="left"
                >
                    <template slot-scope="scope">
                        <el-radio
                            v-model="WaitCarActive"
                            :label="scope.row.plate_small_number"
                        >
                        </el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="plate_small_number"
                    label="车号"
                >
                </el-table-column>
                <el-table-column
                    prop="bulk_density"
                    label="承载量"
                >
                </el-table-column>
                <el-table-column
                    prop="plate_number"
                    label="车牌号"
                >
                </el-table-column>
                <el-table-column
                    prop="driver_name"
                    label="司机姓名"
                >
                </el-table-column>
                <el-table-column
                    prop="driver_phone"
                    label="司机电话"
                >
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: [Array, Object, String ],
        },
    },
    data() {
        return {
            loading: false,
            // 所有待选车辆
            wait_car_list: [],
            // 待选车辆数据
            showDialogWaitCarList: [],
            // 选中车辆车号
            WaitCarActive: '',
            // 选择车辆搜索
            searchWaitCarValue: '',
            // 物流公司编码(经销商选择车辆需要根据物流公司编码过滤)
            logisticsCompanyCode: [],
        };
    },
    computed: {},
    // 监控data中的数据变化
    watch: {
        extr: {
            immediate: true,
            handler: function () {
                this.wait_car_list = this.extr.wait_car_list;
                this.showDialogWaitCarList = JSON.parse(JSON.stringify(this.extr.wait_car_list));
                // 选中车辆车号
                this.WaitCarActive = this.extr.WaitCarActive;
            },
        },
    },
    // 方法集合
    methods: {
        // 选中整行发货车号列表
        rowClickWaitCarChange(row) {
            this.WaitCarActive = row.plate_small_number;
        },
        // 选中等待车辆
        selectWaitCarChange() {
            if (this.WaitCarActive !== '') {
                const row = this.showDialogWaitCarList.filter(item => {
                    return item.plate_small_number === this.WaitCarActive;
                });
                this.extr.sureCallback(row[0]);
                this.$parent.hide();
                this.searchWaitCarValue = '';
            } else {
                this.$message.error('请选择等待车辆');
            }
        },
        // 根据车号搜索等待区车辆
        searchWaitCarChange() {
            this.WaitCarActive = '';
            this.selectedVehicles = {};
            if (this.searchWaitCarValue !== '') {
                const row = this.wait_car_list.filter(item => {
                    // eslint-disable-next-line max-len
                    return item.plate_small_number.indexOf(this.searchWaitCarValue) !== -1 || item.plate_number.indexOf(this.searchWaitCarValue) !== -1;
                });
                this.showDialogWaitCarList = row;
            } else {
                this.showDialogWaitCarList = this.wait_car_list;
            }
        },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.WaitCarActive = '';
        this.selectedVehicles = {};
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        const _that = this;
        document.onkeydown = function () {
            const key = window.event.keyCode;
            if (key === 13) {
                _that.searchWaitCarChange();
            }
        };
    },
    // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() {},
};
</script>
<style lang='stylus'>
.selectVehicle
    height 100%
    padding 0.15rem
    .table-content
        width 100%
        padding 0.1rem
        flex 1
        .search-wrapper
            display flex
            justify-content space-between
            padding-bottom 0.1rem
            .el-input-group__append
                background #409EFF
                width 0.6rem
                text-align center
                border none
                padding 0
                cursor pointer
                .search-btn
                    border none
                    background none
                    cursor pointer
                    width 0.6rem
                    height 0.36rem
                    i
                        color #ffffff
        .select_btn
            background #409EFF
            color #fff
            border none
            width 1rem
            height 0.36rem
            border-radius: 2px
            cursor pointer
        .el-table
            border 1px solid #ccc
            .el-table__header-wrapper
                .el-table__header
                    .has-gutter
                        tr
                            background #edf0f5 !important
            .el-radio__label
                display none
</style>